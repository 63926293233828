import { CiUser } from "react-icons/ci";
import { useEffect, useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { useSelector } from "react-redux";
import { FaRegCommentDots } from "react-icons/fa6"
import like_blue from '../../images/oblue_like-removebg-preview.png'
import likeimg from "../../images/like.png"
import CommentCard from "../../componet/commentCard";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import ReactPlayer from "react-player"
const ProfilePostCard =({data})=>{
    const {_id , userId, img, caption, like,comment,type} = data
    const [commente , setComment]=useState(false)
    const [userPostData, setUserPostData] = useState({})
    const useId = useSelector(state=>state.userStore.userId)
    const userToken =  useSelector(state=>state.userStore.token)
    const msgRef = useRef()
    const [likes, setLikes]=useState(like || [])
    const [ comments, setComments]=useState(comment.items || [])
    const [commentMode, setCommentMode]=useState(false)
    const [likeMode, setLikeMode]=useState(false)
    const [severError, setServerError]=useState("")
    

    useEffect(()=>{
        try {
            const getData =async()=>{
                const fetchData = await fetch(`https://gaoney.online/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const data = await fetchData.json()
                setUserPostData(data.user)
            }
            
            getData()
        } catch (error) {
            setServerError("no Internet")
        }
        
    },[userId, like, useId,userToken])
    
    const handleClick =async (id)=>{
        try {
            const fetchData = await fetch('https://gaoney.online/follow',{
                method : "POST",
                headers : {
                    "Content-Type" : "application/json",
                    Authorization : 'Bearer ' + userToken
                },
                body : JSON.stringify({
                    userId : useId,
                    followingUser : id
                })
            })
            const data = fetchData.json()
            console.log(data)
        
        } catch (error) {
            setServerError("no Internet")
        }

    }
    const handleLike =async(postId)=>{
        setLikeMode(true)
        try {
            const fetchData = await fetch('https://gaoney.online/like',{
                method : "POST",
                headers : {
                    "Content-Type" : "application/json",
                    Authorization : 'Bearer ' + userToken
                },
                body : JSON.stringify({
                    postId : postId,
                    userId : useId
                })
            })
            const data = await fetchData.json()
            console.log(data)
            if(data.msg){
                setLikes([...likes, userId])
            }else{
                const findLike = likes.filter(user => user !== userId)
                setLikes(findLike)
            }
            setLikeMode(false)
        } catch (error) {
            setServerError("no Internet")
        }

    }
    const handleComment =async(id)=>{
        setCommentMode(true)
        const message = {
            postId : id,
            userId : useId,
            msg : msgRef.current.value
        }
        try {
            const fetchData = await fetch('https://gaoney.online/comment',{
                method : "POST",
                headers : {
                    "Content-Type" : "application/json",
                    Authorization : 'Bearer ' + userToken
                },
                body : JSON.stringify(message)
            })
            await fetchData.json()
            setComments([...comments, message])
            // socket.current.on("comment", )
            // socket.current.emit("comment", message)
            msgRef.current.value = ""
            setCommentMode(false)
        } catch (error) {
            setServerError("no Internet")
        }
    }
    const handleDelete = async(_id)=>{
        try {
            const formData = new FormData()
            formData.append("postId", _id)
            console.log(_id)
            const deletePost = await fetch(`https://gaoney.online/delete-post/${_id}`,{
                // method : "POST",
                headers :{
                    Authorization : "Bearer " + userToken
                },
                // body : formData
    
            })
            await deletePost.json()
            window.location.reload()
            
        } catch (error) {
            setServerError("no Internet")
        }
    }
    return(
        <div className="w-full lg:w-wl h-auto  flex flex-col my-2">
        {severError && <div className="w-full flex justify-center text-xl ">{severError}</div>}
            <div className="w-full h-16 border-b flex items-center justify-between">
                <Link to={`/profile/${userPostData?._id}`}>
                    <div className="flex items-center">
                        <div className="w-12 h-12 rounded-full border ml-4 flex items-center justify-center">
                            {
                                userPostData?.profile_img ? (
                                    <img src={userPostData?.profile_img} alt="profile_img " className="rounded-full w-8 h-8" />
                                ) : (
                                    <CiUser className="w-8 h-8"/>
                                )
                            }
                        </div>
                        <h2 className="text-xl text-grey-100 ml-4">{userPostData?.sname} {userPostData?.fname}</h2>

                    </div>
                </Link>
                    {
                         userPostData?._id === useId ? ( 
                            <button onClick={()=>handleDelete(_id)}>
                                <AiOutlineDelete className="text-red-500 h-8 w-8" />
                            </button>
                         ): (
                            userPostData?.follower?.find(flow=>flow === useId ) ? "" : (
                                <button className="mr-3 text-blue-600" onClick={()=>handleClick(userPostData?._id)} >Follow</button>
                            )
                         )
                    }
            </div>
            <div className="w-96 lg:w-full flex-col  items-center my-2">
                <div className=" w-full px-2 flex justify-center">
                    {
                        type === "video" ? (
                            <ReactPlayer url={img} controls={true} />
                        ):(
                            <img src={img} alt="user" className="w-full"/>
                        )
                    }
                </div>
                <div className="ml-2 max-h-16 pt-4">{caption}</div>
            </div>
            <div className="w-full h-12">
                
                    <label htmlFor="chat" className="sr-only">Your message</label>
                    <div className="flex w-full justify-start items-center px-3 py-2 rounded-lg bg-white dark:bg-gray-700 h-8 text-lg">
                        <button type="button" className="p-2 text-gray-500 rounded-lg mx-2 flex items-center" disabled={likeMode} onClick={()=>handleLike(_id)}><p>{likes.length}</p>
                            {
                               
                               likes?.find(lik => lik === useId)  ? <img src={like_blue} alt="like" className="h-8   "/> : <img src={likeimg} alt="like" className="w-8" />
                            }
                            
                        </button>
                        
                        <button type="button" className="px-4 py-2 text-gray-500 rounded-lg  flex" onClick={()=>setComment(true)}>{comments?.length}<FaRegCommentDots className="h-6 w-8" /></button>
                    </div>
                    {
                        commente && (
                            <div className="fixed lg:top-80 bottom-0 lg:right-96 bg-white lg:left-96 top-64 right-2 left-2 bottom-0 border flex flex-col items-center justify-between">
                                <div className="flex flex-col  w-full ">
                                    <div className="w-full flex justify-end">
                                        <button onClick={()=>setComment(!commente)} className="h-8 w-8 rounded-full bg-red-400 mt-2  mr-2 flex justify-center items-center font-bold text-xl text-white">X</button>
                                    </div>
                                    <div className="h-ifull overflow-scroll">
                                        {
                                            comments?.map(ems=>(
                                                <CommentCard key={ems._id} data={ems}/>

                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="w-full flex mb-3">
                                    <textarea id="chat" ref={msgRef} rows="1" className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="comment..."></textarea>
                                    <button onClick={()=>handleComment(_id)} disabled={commentMode} className=" inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600 ">
                                        <IoMdSend className="h-8 w-8 "/>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                
            </div>
        </div>
    )
    
}

export default ProfilePostCard
