// const { createSlice } = require("@reduxjs/toolkit")
import { createSlice } from "@reduxjs/toolkit"


const userSlice = createSlice({
    name : "user",
    initialState : {
        userId : "",
        token : "",
        userData : null
    },
    reducers : {
        getUserId(state,action){
            state.userId = action.payload.userId
            state.token = action.payload.token
        },
        removeUser(state, action){
            state.userData = action.payload
            
        },
        getUserData(state, action){
            state.userData = action.payload
        }
    }
})

export const {getUserId, removeUser,getUserData} = userSlice.actions
export const userReducer = userSlice.reducer