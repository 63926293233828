import { useEffect, useState } from "react"
import tap from "../../images/tap.png"
import { useSelector } from "react-redux"

const TapCount =()=>{
    const [countState, setCountState]=useState(0)
    const [userDash, setUserDash]=useState({})
    const [state, setState]=useState(false)
    const userId = useSelector(state=>state.userStore.userId)
    const userToken =  useSelector(state=>state.userStore.token)
    useEffect(()=>{
        const getDashBoard = async()=>{
            const fetchData = await fetch(`https://gaoney.online/dashboard/${userId}`,{
                headers : {
                    Authorization : 'Bearer ' + userToken
                }
            })
            const {data} = await fetchData.json()
            setUserDash(data)
        }
        getDashBoard()
    },[userId, userToken,state])
    const handleClick=async()=>{
        const formData = new FormData()
        formData.append('userId', userId)
        formData.append("count",countState)
        const addData = await fetch("https://gaoney.online/store-tap",{
            headers : {
                Authorization : 'Bearer ' + userToken
            },
            method : "POST",
            body : formData
        })
        const {data} = await addData.json()
        console.log(data)
        setCountState(0)
        setState(!state)
    }
    return (
        <div className="w-full flex justify-center">
            <div className="w-full lg:w-1/3 h-auto flex flex-col items-center mt-20">
                
                <div className="w-full flex justify-evenly my-4">
                    <div className="py-2 px-8 rounded-2xl border">{userDash?.tap_point || 0}</div>
                    <div className="py-2 px-8 rounded-2xl border">{countState}</div>
                </div>
                <div className="w-full flex justify-center">
                        <button onClick={()=>setCountState(prev => prev + 10)} className="">
                            <img src={tap} alt="tap" className="w-64 rounded-full" />
                        </button>
                </div>
                <div className="w-full flex justify-end pr-8">
                        <button onClick={handleClick} disabled={countState >= 2000 ? false : true} className={`px-3 py-2  ${countState >= 2000 ? "bg-blue-500 text-white" : "bg-grey-100 text-black border border-blue-500"} rounded-lg`}>ADD</button>
                </div>
            </div>
        </div>
    )
}
export default TapCount