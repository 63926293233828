import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import ReactGA from "react-ga4"
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" })

const SendAnomalous = ()=>{
    const inputRef = useRef()
    const params = useParams()
    const [userData, setUserData]=useState({})
    const [state, setState]=useState(false)
    const [messageSent,setMessageSent]=useState(null)

    useEffect(()=>{
        try {
            const fetchUserData =async()=>{
                const fetchData = await fetch(`https://gaoney.online/${params.id}`)
                const {user} = await fetchData.json()
                console.log(user)
                setUserData(user)
            }
            fetchUserData()
            
        } catch (error) {
            
        }
    },[params])
    const handleSubmit =async(e)=>{
        try {
            e.preventDefault()
            const formData = new FormData()
            formData.append('message', inputRef.current.value)
            formData.append('userId', params.id)
            
            const fetchdata = await fetch("https://gaoney.online/anonlymous/send",{
                headers : {
                    "content-type" : "application/json"
                },
                method : "POST",
                body : JSON.stringify({
                    'message': inputRef.current.value,
                    'userId': params.id
                })
            })
            const data =await  fetchdata.json()
            inputRef.current.value = ""
            setMessageSent("Anonlymous sent")
            setState(true)
            console.log(data)
            
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="w-full h-dvh lg:h-auto flex justify-center mt-20 px-3">
            <div className="w-full lg:w-1/3 flex flex-col ">
                {
                    messageSent ? (
                        <div className="w-full bg-green-300 text-xl text-white px-2 py-2 flex justify-between">
                           <span>
                           {messageSent}
                           </span>
                           <button className="h-8 w-8 rounded-full flex justify-center items-center text-black bg-white" onClick={()=>setMessageSent(null)}>
                             X
                           </button>
                        </div>

                    ) : ""
                }
                {
                    !state && (
                        <>
                            <div className="w-full flex justify-start py-2">
                                <div className="w-12 h-12 rounded-full border mx-2">
                                    {
                                        userData?.profile_img ? (
                                            <img src={userData?.profile_img } alt="" className="rounded-full w-12 h-12" />
                                        ): ''
                                    }
                                </div>
                            <div className="h-12 flex text-2xl font-normal items-center ">{userData?.sname} {userData?.fname}</div>
                        </div>
                        <div className="w-full flex flex-col">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <textarea ref={inputRef} className="w-full outline-none border h-32 px-2" >

                                    </textarea>
                                </div>
                                <div className="w-full flex justify-end p-3">
                                    <button type="submit" className="px-3 py-2 bg-blue-500 text-white">Send</button>
                                </div>
                            </form>
                        </div>
                        </>
                    )
                }
                {
                    state && (
                        <div className=" fixed top-64 bottom-56 left-2 right-2 lg:left-72 lg:right-72">
                            <div className="w-full h-full bg-grey-200 flex flex-col items-start justify-center px-4 text-xl">
                                <h1>Hi ! from Gaoney</h1>
                                <p>Let me share some benefit you can also get by using Gaoney. You can earn up NGN 30,000 per month by using Gaoney.<br/> Here are some ways you can earn point and convert it naira</p>
                                <ul className="text-decoration my-2 w-full flex flex-col items-start">
                                    <li>By referrring your friends can earn you a high point</li>
                                    <li>Posting on Gaoney will also earn points</li>
                                    <li>Liking and Commenting on other people's post</li>
                                    <li>Messaging friends and family on Gaoney  and more </li>
                                </ul>
                                <p><span className="text-blue-500 "> <Link to="/signup">Sign up now !! </Link></span>  on Gaoney to start earning in differnt ways</p>
                                <div className="w-full flex justify-end mt-3">
                                    <button className="px-4 py-2 bg-blue-500 rounded-xl mx-2 text-white" onClick={()=>setState(!state)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SendAnomalous