import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

const ForgetFloat=()=>{
    const [countdown, setCountdown]=useState(60)
    const [click, setClick]=useState(true)
    const timer = useRef()
    const navigate = useNavigate()
    const params = useParams()
    const [itTTrue, setIttrue]=useState(false)
    useEffect(()=>{
        timer.current = setInterval(()=>{
            setCountdown(prev => prev -1)
        }, 1000)
        return ()=> clearInterval(timer.current)
    },[])
    useEffect(()=>{
        if(countdown <= 0){
            setClick(false)
            clearInterval(timer.current)
        }
    },[countdown])
    useEffect(()=>{
        const formData = new FormData()
        formData.append("email", params.email)
        try {
            const handleResend =async()=>{
                console.log(params)
                const resendEmail = await fetch("https://gaoney.online/passwordforggotenemail",{
                    method : "POST",
                    body : formData
                })
                const data = await resendEmail.json()
                console.log(data)
                window.location.reload()
            }
            if(itTTrue){
                handleResend()
            }
            
        } catch (error) {
            console.log(error)
        }
    },[itTTrue, params])
    return (
        <div className="w-full mt-20">
            <div className="w-full flex justify-center items-center">
                <div className="lg:w-2/5 w-4/5 px-3 h-56 border rounded-lg bg-blue-500 text-white flex flex-col items-center justify-between py-2">
                    <h2>Email has been sent to you !</h2>
                    <p>If you did not get the email you can resend it or check your spam message </p>
                    <p className="text-gray-200">Resend in <span>{countdown}</span></p>
                    <div className="w-full flex justify-evenly text-black py-2">
                        <button className={`py-2 px-3  rounded-lg hover:bg-blue-400 ${click ?"bg-gray-300" : "bg-white "}`}  disabled={click} onClick={()=>setIttrue(true)}>Resend</button>
                        <button className="py-2 px-3 bg-white rounded-lg hover:bg-blue-400 " onClick={()=>navigate("/login")}> Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgetFloat
