const AboutUs =()=>{
    return(
        <div className="mt-20 w-full flex flex-col items-center">
            <div className="text-3xl w-full flex justify-start px-8">Welcome to <span className="text-blue-700 font-bold ml-2"> Gaoney:</span> Revolutionizing Crypto Rewards </div>
            <div className="w-full flex flex-col  mt-3 text-lg font-normal px-8 flex justify-start">
                <h2 > Gaoney is a pioneering  platform that empowers users  to earn points,  redeemable for our native cryptocurrency, Gaoney Coin(once listed on crypto exchanges). Our mission is to create a user-centric ecosystem fostering engagement, education, and community growth.</h2>
                <p>Key Features:</p>
                <ul>
                    <li>Earn Points: <span>Complete surveys, offers, tasks, and quest to accumulate points</span></li>
                    <li>Redeem for Gaoney Coin: <span>Exchange points for our cryptocurrency, once listed on exchanges or recieve in USD if your accout is up to 8 weeks </span></li>
                    <li>Educational Contents: <span>Access a wealth of resources, including quizzes</span></li>
                    <li>community Forum: <span>Engage with like-minded individuals, paticipate in discussions, and share knowledge</span></li>
                    <li>Referral Program:<span>Invite friends, earn point, and climb the referral leaderboard</span></li>
                </ul>
                <p>Benefits:</p>
                <ul>
                    <li>User-Friendly Interface: <span>Seamless  navigation and intuitive design</span></li>
                    <li>Veriety of Earning Oppurtunities: <span>Diversify your point earnings through multiple channel</span></li>
                    <li>Educational Resources: <span>Enhance your crypto knowledge and stay updated on industry trends</span></li>
                    <li>Rewarding Experience: <span>Redeem points for Gaoney Coin and watch your balance grow</span></li>
                </ul>
                <p>Join the Gaoney Movement</p>
                <p>Be part of a revolutionary  platform that's shaping the future of cryto rewards. Sign Up today, start earning points and contribute to the growth of our vibrant community! </p>
            </div>
            <div className="w-full flex flex-col  mt-10 px-10">
                <h1 className="text-2xl my-2">Contact Us</h1>
                <form className="w-full lg:w-3/5 ">
                    <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                    <div className="relative mb-6">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                        </svg>
                    </div>
                    <input type="text" id="input-group-1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@mail.com"/>
                    </div>
                    <label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                    <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </svg>
                    </span>
                    <input type="text" id="website-admin" className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name" />
                    </div>
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                    <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 my-2">Send</button>
                </form>
                

            </div>
        </div>
    )
}

export default AboutUs


