import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import referralImg from "../../images/referral.png"
import apImg from "../../images/ap.png"


const Goals =()=>{
    const [state, setState]=useState({})
    const userToken =  useSelector(state=>state.userStore.token)
    const userId = useSelector(state=>state.userStore.userId)
    useEffect(()=>{
        try {
            const getDashBoard = async()=>{
                const fetchData = await fetch(`https://gaoney.online/dashboard/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const {data} = await fetchData.json()
                setState(data)
            }
            getDashBoard()
        } catch (error) {
            console.log(error)
        }
    },[userId,userToken])
    const handleWidthFormat =(term)=>{
        if(term >= 50){
            return  "full"
        }else if(term >= 40){
            return  4
        }else if(term >= 30){
            return  3
        }else if(term >= 20){
            return  2
        }else if(term >= 10) {
            return  1
        }else{
            return 0
        }
    }
    const handlel =(term)=>{
        if(term >= 10){
            return  "full"
        }else if(term >= 8){
            return  4
        }else if(term >= 6){
            return  3
        }else if(term >= 4){
            return  2
        }else if(term >= 1) {
            return  1
        }else {
            return 0
        }
    }
    const handleClaim  =async(title)=>{
        const sendClaim = await fetch(`https://gaoney.online/addupdate/${userId}/${title}`,{
            headers : {
                Authorization : 'Bearer ' + userToken
            }
        })
        await sendClaim.json()
        window.location.reload()
    }
    
    return (
        <div className="w-full lg:w-1/3 flex flex-col items-center p-2" >
            <button className="w-full  h-auto border my-2 p-2 flex justify-between items-center rounded-lg" disabled={state.anonlymous_coll < 50} onClick={()=>handleClaim("anonlymous")}>
                <div className="w-8 h-8 rounded-full border">
                    <img src={apImg} alt="" className="w-8 h-8 rounded-full" />
                </div>
                <div className="w-64 h-auto flex flex-col justify-center">
                    <div className="p-2 w-64 flex justify-between">
                        <div className="text-xl">Anonymous</div>
                        <div >{state?.anonlymous_collt || 0}/50</div>
                    </div>
                    <div className="w-64 relative">
                        <div className="w-full h-4 rounded-lg border relaive z-0 "></div>
                        <div className={`w-${handleWidthFormat(state?.anonlymous_coll)}/5 h-4 rounded-lg border absolute z-10 bg-blue-400 top-0`}></div>
                    </div>
                </div>
                <div className={`px-3 py-3 border rounded-lg ${state.anonlymous_coll >= 50 ? "bg-blue-500 text-white" : "bg-white border-blue-500 text-black"  }`}>5000</div>
            </button>
            <button className="w-full h-auto border p-2 flex justify-between items-center rounded-lg" disabled={state.post_coll < 50} onClick={()=>handleClaim("post")}>
                <div className="w-8 h-8 rounded-full border">
                <img src={apImg} alt="" className="w-8 h-8 rounded-full" />
                </div>
                <div className="w-64 h-auto flex flex-col justify-center">
                    <div className="p-2 w-64 flex justify-between">
                        <p className="text-xl">Post</p>
                        <div>{state?.post_coll|| 0}/50</div>
                    </div>
                    <div className="w-64 relative">
                        <div className="w-full h-4 rounded-lg border relaive z-10 "></div>
                        <div className={`w-${handleWidthFormat(state?.post_coll)}/5 h-4 rounded-lg border absolute z-20 bg-blue-400 top-0`}></div>
                    </div>
                </div>
                <div className={`px-3 py-3 border rounded-lg  ${state.post_coll >= 50 ? "bg-blue-500 text-white" : "bg-white border-blue-500 text-black" }`}>5000</div>
            </button>
            <button className="w-full my-2 h-auto border p-2 flex justify-between items-center rounded-lg" disabled={state.referral_coll < 10} onClick={()=>handleClaim("referral")}>
                <div className="w-8 h-8 rounded-full border">
                <img src={referralImg} alt="" className="w-8 h-8 rounded-full" />
                </div>
                <div className="w-64 h-auto flex flex-col justify-center">
                    <div className="p-2 w-64 flex justify-between">
                        <p className="text-xl">Referral</p>
                        <div>{state?.referral_coll|| 0}/10</div>
                    </div>
                    <div className="w-64 relative">
                        <div className="w-full h-4 rounded-lg border relaive z-10 "></div>
                        <div className={`w-${handlel(state?.referral_coll)}/5 h-4 rounded-lg border absolute z-20 bg-blue-400 top-0`}></div>
                    </div>
                </div>
                <div className={`px-3 py-3 border rounded-lg ${state.referral_coll >= 10 ? "bg-blue-500 text-white" : "bg-white border-blue-500 text-black" }`}>5000</div>
            </button>
        </div>
    )
}
export default Goals