import { Link } from "react-router-dom"

const PageNotFound = ()=>{
    return(
        <div className="w-full flex justify-center mt-20 text-3xl">
            <div className="w-full lg:w-1/3 mt-4">
                <h1><span className="text-6xl font-bold mt-16">404</span> </h1>
                <p>PAGE NOT FOUND</p>
                <div className="w-full flex justify-end px-2 mt-16">
                    <Link to="/">
                        <button className="text-lg  text-white px-3 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg">Back To Home </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound