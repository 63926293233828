import { useRef, useState } from "react"
import { useParams } from "react-router-dom"




const ResetPass =()=>{
    const password = useRef()
    const confirmPassword = useRef()
    const params = useParams()
    const [errorMessage, setErrorMessage]=useState(null)
    const [movePage, setMovePage]=useState(false)
    const [loading, setLoading]=useState(false)

    console.log(params)
    const handleSubmit = async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("pass",password.current.value)
        formData.append("cpass", confirmPassword.current.value)
        try {
            setLoading(true)
            const fetchChangePassword = await fetch(`https://gaoney.online/changepassword/${params.userId}/${params.token}`,{
                headers : {
                    Authorization : 'Bearer ' + params.token
                },
                method : "POST",
                body : formData
            })
            setLoading(true)
            const data = await fetchChangePassword.json()
            setLoading(false)
            if(data.statusCode === 401){
                return setErrorMessage("Your Password does not match")
            }
            setMovePage(true)
        } catch (error) {
            console.log(error)
        }
    }
    return(
        <div className="w-full h-full bg-white flex justify-center items-center mt-24">

            <div className="w-full lg:w-2/5  bg-white rounded-lg shadow-lg p-4 mt-3">
            {
                loading && <div className="w-full flex justify-center text-xl my-2">Loading...</div>
            }
            {
                movePage && <div className="px-3 py-2 bg-green-500 text-white">Your Password as been changed</div>
            }
            {
                errorMessage && <div className="px-3 py-2 bg-red-500 text-white">{errorMessage}</div>
            }
                <form onSubmit={handleSubmit}>
                    
                    <div className="mb-6">
                    <label htmlFor="success" className="block mb-2 text-sm font-medium ">Password</label>
                    <input type="text" id="success" ref={password} className="border  placeholder-gray-300 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" placeholder="........." />
                    <p className="mt-2 text-sm font-medium">Password should be at least 8</p>
                    </div>
                    <div>
                    <label htmlFor="error" className="block mb-2 text-sm font-medium ">Confirmed password</label>
                    <input type="text" ref={confirmPassword} id="error" className="border text-red-900 placeholder-gray-300 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" placeholder="........" />
                    <p className="mt-2 text-sm font-medium dark:text-red-500">Password should be at least 8</p>
                    </div>
                    <div className="w-full flex justify-end my-2">
                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Reset</button>
                    </div>
                </form>
            </div>

        </div>
    )
}


export  default ResetPass