import { useEffect, useRef, useState } from "react"
import QuizData from "./QuizData";
import { Link, useParams } from "react-router-dom";
import coin from "../../images/G-removebg-preview.png"
import { useSelector } from "react-redux";
import ReactGA from "react-ga4"
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" })

const QuizingPage =()=>{
    const [score, setScore]=useState(0)
    const [result, setResult]=useState(false)
    const params = useParams()
    const [countdown, setCountdown] =useState(Number(params.qus_no )* 30)
    const timer = useRef()
    const userId = useSelector(state=>state.userStore.userId)
    const userToken =  useSelector(state=>state.userStore.token)
    const [error, setError]=useState("")
    const [question, setQestion]=useState([])
    const [ans, setAns]=useState([])
    const [index, setIndex]=useState(0)
    const [holdQeustion, setHoldQuestion]=useState([])
    useEffect(()=>{
        console.log(params)
        try {
            const fetchDataFromUrl =async()=>{
                const fetchData = await fetch(`https://opentdb.com/api.php?amount=10&category=${params.category}&difficulty=${params.difficulty}&type=multiple`)
                const data = await fetchData.json()
                console.log(data)
                setQestion(data.results)
            }
            fetchDataFromUrl()
            
        } catch (error) {
            console.log(error)
        }
        
    },[params])
    useEffect(()=>{
        if(countdown === 0){
            setResult(true)
        }

    },[countdown])
    useEffect(()=>{
        const navigateQuiz =[
            {
                category : 9,
                difficulty :  'easy',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 9,
                difficulty :  'medium',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 9,
                difficulty :  'hard',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 10,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 10,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 10,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 11,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 11,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 11,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 13,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 13,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 13,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 14,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 14,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 14,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 15,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 15,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 15,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 16,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 16,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 16,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 17,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 17,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 17,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 18,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 18,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 18,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 19,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 19,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 19,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 20,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 20,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 20,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 21,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 21,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 21,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 22,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 22,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 22,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 23,
                difficulty :  'easy',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 23,
                difficulty :  'medium',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 23,
                difficulty :  'hard',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 24,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 24,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 24,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 25,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 25,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 25,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 26,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 26,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 26,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 27,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 27,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 27,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 28,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 28,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 28,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 30,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 30,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 30,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 29,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
            {
                category : 29,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
            {
                category : 29,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
        ]
        if(result){
            const sendQuizScore = async()=>{
                const fetchQuiz = await fetch(`https://gaoney.online/allquiz/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const {data}= await fetchQuiz.json()
                const arr= []
                const finish = []
                let existingModel;
                for (let i = 0; i < data.model.length; i++) {
                    const filterArray = data.model[i].title.trim() === params.title.trim()
                    const filter = navigateQuiz.find(state=>state.name.trim() === data.model[i].title.trim())
                    if(filterArray){
                        existingModel = data.model[i].difficulty.push(params.difficulty)
                    }
                    arr.push(data.model[i])
                    if(filter){
                        finish.push(filter)
                    }
                }
                let model = {}
                console.log(existingModel)
                if(existingModel){
                    model = existingModel 
                }else{
                    model = {
                        title: params.title,
                        difficulty : [params.difficulty.trim()]
                    }
                }
                
                let amount ;
                if(params.difficulty.trim() === "easy"){
                    amount = 500
                }else if(params.difficulty.trim() === "medium"){
                    amount = 700
                }else if(params.difficulty.trim() === "hard"){
                    amount = 1000
                }
                if(userId){
                    const formData =new FormData()
                    formData.append("userId", userId)
                    if(data.model.length){
                        if(finish.length === 65){
                            amount  += 10000
                        }
                    }
                    formData.append("amount", amount)
                    formData.append("model", model)
                    const send = await fetch("https://gaoney.online/update-quiz",{
                        headers : {
                            Authorization : 'Bearer ' + userToken,
                            'Content-Type' :"application/json"
                        },
                        method : "POST",
                        body : JSON.stringify({
                            userId : userId,
                            amount  : amount,
                            model : model,
                            finish : finish
                        })
                    })
                    await send.json()
                }else{
                    setError("You are not login")
                }
            }
            sendQuizScore()
        }
    },[params,result,userId,userToken])
    
   
    const [click, setClick]=useState(false)
    
    function formatTime(time){
        let min = Math.floor(time / 60)
        let sec = Math.floor(time - min * 60)

        if(min <= 10) min = '0'+ min
        if(sec <= 10) sec = '0'+ sec

        return min + ':' + sec
    }
    
    useEffect(()=>{
        if(question?.length){
            setHoldQuestion([...question])
        }
            console.log(question)
        },[index,question])
    useEffect(()=>{
        if(holdQeustion?.length){
            setAns([...holdQeustion[index]?.incorrect_answers,holdQeustion[index]?.correct_answer ])
        }
    },[index,holdQeustion])
    useEffect(()=>{
        timer.current = setInterval(()=>{
            setCountdown(prev => prev -1)
        }, 1000)
        return ()=> clearInterval(timer.current)
    },[])

    useEffect(()=>{
        if(countdown <= 0){
            clearInterval(timer.current)
            setClick(true)
        }
    },[countdown])
    useEffect(()=>{
        if(click){
            clearInterval(timer.current)
        }
    },[click])
    return(
        <>
        {
            result && <div className="w-full pt-20 h-auto px-4 flex bg-white flex-col items-center">
                <div className="w-full h-full flex  px-3">
                    <div className="w-full lg:w-2/4 flex flex-col justify-center items-center lg:border-r h-auto lg:h-auto">
                        <div className="text-black text-xl">
                            {
                                score > 8 ? "YOU WON" : "YOU LOSE"
                            }
                        </div>
                        {
                              score > 8  ? (
                                <div className="h-auto w-full py-3 px-2 flex flex-col items-center">
                                    {
                                        params?.difficulty === "easy" && <span className="text-xl">500</span>
                                    }
                                    {
                                        params?.difficulty === "medium" && <span className="text-xl">700</span>
                                    }
                                    {
                                        params?.difficulty === "hard" && <span className="text-xl">1000</span>
                                    }
                                    
                                    <img src={coin} alt="coin" />
                                </div>

                             ) : ""
                        }
                        <div className="w-full flex justify-end">
                            <Link to="/quiz">
                                <button className="text-lg px-3 py-2 text-white bg-blue-500 rounded-lg">Quiz Page</button>
                            </Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        }
        { !result &&
            <div className="w-full pt-20 h-dvh px-4 flex bg-gray-50 flex-col items-center">
                {
                    error && 
                    <div className="w-full h-16  bg-red-500 text-white rounded-lg flex justify-center items-center text-xl">{error}</div>
                }
                <div className="w-full lg:w-1/3 flex flex-col items-center bg-gray-50 mt-8 lg:-mt-4 pt-3">
                    <div className="w-full h-10 flex justify-between">
                        <div>Score: {score}</div>
                        <div className="w-24 h-full text-2xl flex justify-center items-center text-red-500">{formatTime(countdown)}</div>
                        <div className="w-14 h-full flex justify-center items-center text-xl text-gray-400">{index + 1}/{holdQeustion?.length}</div>
                    </div>
                    <QuizData 
                    question={holdQeustion} 
                    index={index}
                    setScore={setScore}
                    setIndex ={setIndex}
                    setResult={setResult}
                    ans={ans}
                    />
                    
                </div>
                
            </div>
        }
        </>
    )
}

export default QuizingPage