import {  useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi"
import { Link } from "react-router-dom"
import CopyToClipboard from "react-copy-to-clipboard"
import referralImg from "../../images/referral.png"
import quizImg from "../../images/quiz.png"
import apImg from "../../images/ap.png"
import tpImg from "../../images/tap.png"
import g_coin from "../../images/g_coin.png"

const DashBoard =()=>{
    const userToken =  useSelector(state=>state.userStore.token)
    const userId = useSelector(state=>state.userStore.userId)
    const [referral, setReferral]=useState(false)
    const [active, setActive]=useState(false)
    const [dashboard, setDashboard]=useState({})
    const [convert, setConvert]=useState(false)
    const inputRef = useRef()
    const ink = `https://gaoney.com/signup/${userId}`


    useEffect(()=>{
        try {
            const getDashBoard = async()=>{
                const fetchData = await fetch(`https://gaoney.online/dashboard/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const {data} = await fetchData.json()
                console.log(data)
                setDashboard(data)
            }
            getDashBoard()
        } catch (error) {
            console.log(error)
        }
    },[userId, userToken])

    const handleClick =()=>{
        setConvert(true)
         setTimeout(() => {
            setConvert(false)
        }, 10000);
    }
    
    const handleText =(e)=>{
        inputRef.current.select()
        console.log(e.target)
    }

    
    return(
        <div className="w-full flex justify-center mt-20 pb-3">
                            <div className="w-full lg:w-3/5 flex flex-col text-xl ">
                                {
                                    convert && <div className="w-auto px-3 py-2 border border-red-500 bg-red-300 text-white">Oops! Your account is not up to 8 weeks</div>
                                }
                                <h1 className="ml-4 my-2">DashBoard</h1> 
                                <div className="w-full px-4">
                                    <div className="w-full flex flex-col">
                                            <div className="w-full flex justify-start items-center">
                                                <div className="">
                                                    <img src={g_coin} alt="" className="w-8 h-8 rounded-full m-2" />
                                                </div>
                                                <div>G-slot</div>
                                            </div>
                                            <div className="w-full h-auto bg-gray-200 text-2xl flex flex-col rounded">
                                                <h1 className="ml-4 my-2">{dashboard?.gaoney_coin}</h1> 
                                                <div className="w-full h-auto flex items-end flex-col my-2 px-2">
                                                    <div className="text-lg px-4">Covertion rate</div>
                                                    <div className="w-full flex gap-3 mx-2 text-sm justify-end py-2">
                                                        <div>1 G-slot</div>
                                                        <div>0.001 USD</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-end text-lg pr-4">
                                                    <button onClick={handleClick} className="py-2 px-3 bg-blue-500 text-white rounded-lg my-2"> Convert</button>
                                                </div>
                                            <div className="w-full flex justify-between px-4 py-3 text-xl">
                                                <h1>Earnings</h1>
                                            </div>
                                            <div className="w-full flex justify-evenly my-3 flex-wrap">
                                                <div className="w-2/5 flex flex-col mx-1 my-2">
                                                    <div className="w-full flex justify-start items-center">
                                                        <div className="">
                                                            <img src={referralImg} alt="" className="w-8 h-8 rounded-full m-2" />
                                                        </div>
                                                        <div>point</div>
                                                    </div>
                                                    <div className="w-full h-24 bg-gray-200 flex items-center justify-center text-2xl">{dashboard?.referral_point }</div>
                                                </div>
                                                <div className="w-2/5 flex flex-col mx-1 my-2">
                                                <div className="w-full flex justify-start items-center">
                                                        <div className="">
                                                            <img src={quizImg} alt="" className="w-8 h-8 rounded-full m-2" />
                                                        </div>
                                                        <div>point</div>
                                                    </div>
                                                    <div className="w-full h-24 bg-gray-200 flex items-center justify-center text-2xl">{dashboard?.quiz_point}</div>
                                                </div>
                                                <div className="w-2/5 flex flex-col mx-1 my-2">
                                                <div className="w-full flex justify-start items-center">
                                                        <div className="">
                                                            <img src={apImg} alt="" className="w-8 h-8 rounded-full m-2" />
                                                        </div>
                                                        <div>point</div>
                                                    </div>
                                                    <div className="w-full h-24 bg-gray-200 flex items-center justify-center text-2xl">{dashboard?.anonlymous_post}</div>
                                                </div>
                                                <div className="w-2/5 flex flex-col mx-1 my-2">
                                                <div className="w-full flex justify-start items-center">
                                                        <div className="">
                                                            <img src={tpImg} alt="" className="w-8 h-8 rounded-full m-2" />
                                                        </div>
                                                        <div>point</div>
                                                    </div>
                                                    <div className="w-full h-24 bg-gray-200 flex items-center justify-center text-2xl">{dashboard?.tap_point}</div>
                                                </div>
                                                <div className="w-full flex justify-end text-lg pr-4">
                                                    <Link  to="/dashboard/convert" className="py-2 px-3 bg-blue-500 text-white rounded-lg my-2"> Convert</Link>
                                                </div>
                                                
                                            </div>
                                <div className="w-full">
                                    <div className="w-full flex flex-col">
                                        <div className="w-full px-2 py-4 border-t flex justify-between text-black text-2xl ">
                                            <div>Refer Friends, Earn Points, and  Boost Gaoney Balance</div>
                                            <button onClick={()=>setReferral(!referral)}>
                                                {
                                                    referral ? (
                                                        <TfiAngleUp />
                                                    ) : (
                                                        <TfiAngleDown />
                                                    )
                                                }
                                            </button>
                                        </div>
                                        {
                                            referral &&(
                                                <div className="w-full flex flex-col px-2 text-lg py-3">
                                                    <div>
                                                        <p>Invite your friends to join the Gaoney community and earn referral points, redeemable for Gaoney Coin (once listed on crypto echanges)! Our referral program is a great way to share the benefits of Gaoney with your network while increasing your own rewards.</p>
                                                        <p>How it work</p>
                                                        <ul>
                                                            <li>Share your unique referral link with friends and family via social media, email,, or messaging apps.</li>
                                                            <li>When they sign up using your link, you recieve referral points</li>
                                                            <li>Earn point for each referral, redeemable for Gaoney Coin</li>
                                                            <li>Track your referrals and point in your Gaoney acccount dashboard</li>
                                                        </ul>
                                                        <p>Referral Program Benefits:</p>
                                                        <ul>
                                                            <li>Earn points for each referral, redeemable for Gaoney Coin</li>
                                                            <li>Increase your Gaoney balance and get closer to redeeming rewards</li>
                                                            <li>Help friends discover the benefits of Gaoney and grow the community</li>
                                                            <li>Climb the referral leaderboard and become a top referrer</li>
                                                        </ul>
                                                        <p>Referral Points Structure:</p>
                                                        <ul>
                                                            <li>Refer 1 friend: 500 point</li>
                                                            <li>Refer 5 friends within 24 hrs: 2500 points + 10,000 bonus points</li>
                                                            <li>Refer 10 friends within 24hrs: 5000 points + 25,000 bonus points</li>
                                                        </ul>
                                                        <p>Redeem Your point for Gaoney Coin:</p>
                                                        <p>Once Gaoney Coin is listed on crypto exchanges, you can redeem your accumulated points for our native cryptocurrency</p>
                                                    </div>
                                                    <div className="w-full flex flex-wrap justify-center my-2">
                                                        <input ref={inputRef} onFocus={handleText} type="text" className="px-2 my-2 w-96 flex flex-grow-1 py-2 border " readOnly value={ink} />
                                                        <CopyToClipboard text={ink}>
                                                            <button  className="mx-2 py-1 px-3 border" >copy</button>
                                                        </CopyToClipboard>
                                                    </div>


                                                </div>
                                            )
                                        }
                                        <div className="w-full px-2 py-4 border-t-2 flex justify-between text-black text-2xl ">
                                            <div>Earn point on Gaoney: A comprehensive Guide</div>
                                            <button onClick={()=>setActive(!active)}>
                                                {
                                                    active ? (
                                                        <TfiAngleUp />
                                                    ) : (
                                                        <TfiAngleDown />
                                                    )
                                                }
                                            </button>
                                        </div>
                                        {
                                            active &&(
                                                <div className="w-full flex flex-col items-center py-3 px-2 text-lg">
                                                    <p>Gaoney offers a veriety of ways to earn points, redeemable for Gaoney Coin. Here's a step-by-step guide to help you get started:</p>
                                                    <ul>
                                                        <li>Complete Surveys and Offers</li>
                                                        <li>Engage with Educational Content</li>
                                                        <li>Refer Friends and Family</li>
                                                    </ul>
                                                </div>
                                            )
                                        } 
                                    </div>
                                </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
    )
}


export default DashBoard