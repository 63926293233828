import { useNavigate, useParams } from "react-router-dom"
import done from "../../images/done.png"
import { useEffect } from "react"
const VerifyEmail =()=>{
    const params = useParams()
    const navigate = useNavigate()
    useEffect(()=>{
        const verifyUserEmail = async()=>{
            const fetchData = await fetch(`https://gaoney.online/verify/${params.sendrandom}/${params.userId}`)
            await fetchData.json()
        }
        verifyUserEmail()
    },[params])
    return(
        <div className="w-full mt-20 px-4 py-4">
            <div className="w-full lg:w-2/4 flex flex-col items-center py-8">
                <div className="w-full h-56  rounded-lg flex flex-col items-center bg-white">
                    <h1 className="text-2xl mb-3">Your Email has been verify </h1>
                    <img src={done} alt="done" className="h-32"/>
                </div>
                <div className="w-full flex justify-center py-2">
                    <button className="py-2 px-3 rounded-lg py-2 bg-blue-500 text-white" onClick={()=>navigate("/login")}> Login</button>
                </div>
            </div>
        </div>
    )
}
export default VerifyEmail