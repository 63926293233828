import { useSelector } from "react-redux"
import ProfilePost from "./ProfilePost"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import avater from "../../images/images/profile-icon.jpg"

const Profile =()=>{
    const userData = useSelector(state=>state.userStore.userData)
    const useId = useSelector(state=>state.userStore.userId)
    const userToken = useSelector(state=>state.userStore.token)
    const [state, setState]=useState({})
    const [editState, setEditState]=useState(false)
    // const [imgState, setImgState]=useState(null)
    const imgRef = useRef()
    const fname = useRef()
    const sname = useRef()
    const email = useRef()
    const params = useParams()
    const [postImage, setPostImage]=useState(null)
    useEffect(()=>{
        const findUserData =async()=>{
            const fetchData = await fetch(`https://gaoney.online/${params.id}`,{
                headers : {
                    Authorization : "Bearer " + userToken
                }
            })
            const data = await fetchData.json()
            setState(data.user)
        }
        if(params.id){
            findUserData()
        }
    },[params, userData, userToken])
    useEffect(()=>{
        if(editState){
            fname.current.value = state.fname
            sname.current.value = state.sname
            email.current.value = state.email
        }
    },[editState, state])
    const handleUpdate = async(e)=>{
        e.preventDefault()
        console.log(imgRef.current.value)
        const formData = new FormData()
        formData.append("fname", fname.current.value)
        formData.append("userId", state._id)
        formData.append("sname", sname.current.value)
        formData.append("email", email.current.value)
        if(imgRef.current.files){
            formData.append("avater", postImage)
        }
        try {
            const fetchData = await fetch("https://gaoney.online/update-user",{
                headers:{
                    Authorization : "Bearer " + userToken
                },
                method : "POST",
                body : formData
            })
            const data = await fetchData.json()
            window.location.reload()
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }
    
    

    const handleImageClick =()=>{
        imgRef.current.click()
    }
    const handlefileUpload =async(e)=>{
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload=()=>{
            setPostImage(fileReader.result)
        }
        fileReader.onerror=(error)=>{
            console.log(error)
        }
    }

    return(
        <>
        {
            editState ?(
                <div className="fixed top-0 bottom-0 right-0 left-0 lg:bottom-24 lg:left-96 lg:right-96 lg:top-24 border rounded-lg shadow bg-white">
                    <div className="w-full h-full flex flex-col">
                        <div className="w-full flex justify-end">
                            <button onClick={()=>setEditState(false)} className="h-10 w-10  rounded-full flex justify-center items-center text-xl m-2 bg-red-400 text-white font-bold">X</button>
                        </div>
                        <form onSubmit={handleUpdate}>
                            <div className="w-full flex flex-col ">
                                <div className="w-full  flex flex-col items-center mb-2">
                                    <div className="h-32 w-32 border rounded-full mx-3">

                                        <img src={ postImage || state.profile_img || avater }  alt="" className="h-32 w-32 border rounded-full" />
                                    
                                    </div>
                                    <input type="file" ref={imgRef} className="hidden"  onChange={(e)=>handlefileUpload(e)} accept=".jpeg, .jpg, .png" />
                                    <button type="button"  className="py-2.5 mt-3 px-5 me-2 mb-2 text-sm font-medium text-gray-900 border focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={handleImageClick} >Upload Image</button>
                                </div>
                                <div className="w-full flex flex-col mt-3">
                                    <div className="w-full h-10  flex items-center pl-3 mt-2">
                                        <label>First Name :</label>
                                        <input ref={fname} type="text" placeholder="fawas" className="w-3/5 border-b pl-3 h-8 border-black outline-none" />
                                    </div>
                                    <div className="w-full h-10  flex items-center pl-3 mt-2">
                                        <label>Surname :</label>
                                        <input ref={sname} type="text" placeholder="Abdul-wahab" className="w-3/5 border-b pl-3 h-8 border-black outline-none" />
                                    </div>
                                    <div className="w-full h-10 flex items-center pl-3 mt-2">
                                        <label>email :</label>
                                        <input ref={email} type="text" placeholder="email" className="w-3/5 border-b pl-3 h-8 border-black outline-none" readOnly/>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-end mt-4">
                                <button type="submit" className="py-2 px-3 bg-red-400 text-white mr-4 rounded">Edit</button>
                            </div>
                        </form>

                    </div>

                </div>

            ): (
                <div className="mt-20 w-full">
                <div className="h-auto w-full flex flex-col items-center">
                    <div className="w-32 h-32 rounded-full border">
                        {
                            state?.profile_img ? (
                                <img src={state.profile_img} alt="profil_img" className="w-32 h-32 rounded-full border"/>

                            ) : ""
                        }
                    </div>
                    <h2 className="my-2 px-3 text-xl ">{state.fname} {state.sname}</h2>
                    <div className="w-auto flex px-4">
                        <div className="p-3 mx-3  text-xm text-gray-400 rounded-lg my-3 flex flex-col items-center justify-start"> <span className="text-xl text-black">{state?.follower?.length}</span> Follower </div>
                        <div className="p-3 mx-3  text-xm text-gray-400 rounded-lg my-3 flex flex-col items-center justify-start"> <span className="text-xl text-black">{state?.following?.length}</span> Following </div>
                    </div>
                    <div>
                        {
                            state._id === useId ? (
                                <button onClick={()=>setEditState(true)} className={state._id === useId ? "flex text-red-500" : "hidden"}>Edit Profile</button>

                            ) : (
                                <div className="flex justify-center"></div>

                            )
                        }
                    </div>
                </div>
                <div className="mt-3 w-full px-2 lg:px-6 flex justify-start flex-wrap">
                    <ProfilePost id={state._id} />
                </div>
            </div>
            )
        }    
        </>
    )
}
export default Profile


