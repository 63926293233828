import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const CommentCard =({data})=>{
    const {userId, msg}=data
    const [state , setState]=useState({}) 
    const userToken =  useSelector(state=>state.userStore.token)
    useEffect(()=>{
        try {
            const fetchData = async()=>{
                const fetchUrl = await fetch(`https://gaoney.online/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const data = await fetchUrl.json()
                setState(data.user)
            }
            fetchData()
        } catch (error) {
            
        }
    },[userId, userToken])
    return (
        <div className="w-full flex justify-start h-10 items-center my-2">
            <div className="h-8 w-8 rounded-full border mx-2"></div>
            <div className="flex  flex-col">
                <div className="text-sm text-gray-500"> 
                    {state?.fname} {state?.sname}
                </div>
                <div className="text-sm text-black">{msg}</div>
            </div>
        </div>
    )
}

export default CommentCard