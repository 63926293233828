import { useEffect, useState} from "react"
import "./quizData.css"
import { MdOutlineNavigateNext } from "react-icons/md"
import { useNavigate } from "react-router-dom"


const QuizData =({question,index, setScore, setIndex, setResult, ans})=>{
    const [selected, setSelected]=useState(null)
    const [error, setError]=useState(null)
    const navigate = useNavigate()
    try {
    
        useEffect(()=>{
            ans?.sort(()=>Math.random()-0.5)
        },[ans])
    const handleSelected =(i)=>{
        if(selected === i && selected === question[index]?.correct_answer){
            return "bg-green-500 text-white"
        }else if(selected === i && selected !== question[index]?.correct_answer){
            return "bg-red-500 text-white"
        }else if (i === question[index]?.correct_answer){
            return "bg-green-500 text-white"
        }
    }
    const handleClick =(i)=>{
        setSelected(i)
        if(i === question[index].correct_answer){
            setScore((prev)=>prev + 1)
        }
        setError(false)
    }
    const handleNext =()=>{
        if(index > 8){
            setResult(true)
        }else if(selected){
            setIndex(prev=> prev + 1)
            setSelected(null)
        }else{ 
            setError("Please select an option ")
        }
    }
    return(
        <div className="w-full h-auto border rounded-lg  shadow-lg  bg-white py-3">
            {
                error && <div className="w-full h-16  bg-red-500 text-white rounded-lg flex justify-center items-center text-xl">{error}</div>
            }
            
            <div className="h-auto lg:h-32  flex justify-center items-center align-center">  
                 <p className="font-bold text-xl text-black flex items-center justify-center text-center">{question?.length ?question[index]?.question : ""}</p>
            </div>
            <div className="w-full flex flex-col justify-center px-2 mt-6 text-xl text-gray-500">
                { 
                    ans?.map((i, index)=>(
                        <button   
                        key={index}
                        onClick={()=>handleClick(i)}
                        disabled={selected}
                        className={`w-full mb-2  rounded-xl border my-3 flex items-center px-4 justify-start py-4 ${selected && handleSelected(i)}`}
                         >{i}</button>
                    ))
                }
                
            </div>
            <button className="w-full lg:w-1/3 flex justify-end mt-4 pr-4">
                <MdOutlineNavigateNext className="w-12 h-12 text-red-500" onClick={handleNext} />
            </button>
        </div>
    )
} catch (error) {
    navigate("/error")
}
}
export default QuizData

