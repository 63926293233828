import Goals from "./goals"

const MissionPage =()=>{
    
    return(
        <div className="w-full h-auto flex flex-col items-center mt-20">
            <Goals />
        </div>
    )
}
export default MissionPage