import { Link } from "react-router-dom"
import benz from "../../images/Quiz-time.png"
import { useEffect, useState } from "react";

const QuizCard =({data})=>{
    const {category, name, qus_no, difficulty}=data
    const [state, setState]=useState(0)
    useEffect(()=>{
        if(difficulty === 'easy'){
            setState(500)

        }else if(difficulty === 'medium'){
            setState(700)
        }else{
            setState(1000)
        }
    },[qus_no, difficulty])
    return(
        <div className="w-full lg:w-80 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 my-3">
            <Link to={`/quiz/${category}/${qus_no}/${difficulty}/${name}`}>
                <img className="rounded-t-lg" src={benz} alt="benz" />
            </Link>
            <div className="p-5">
                <Link to={`/quiz/${category}/${qus_no}/${difficulty}//${name}`} >
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{name}</h5>
                </Link>
                <div className="flex flex-col text-lg">
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Difficulty : {difficulty} </p>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Qustion No :{qus_no} </p>
                </div>
                <div className="flex justify-between items-center text-lg">
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Win : {state}</p>
                    <Link to={`/quiz/${category}/${qus_no}/${difficulty}/${name}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Play
                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default QuizCard