import { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const Post =()=>{
    const imgRef = useRef()
    const videoRef = useRef()
    const capRef = useRef()
    const navigate = useNavigate()
    const userId = useSelector(state=>state.userStore.userId)
    const userToken = useSelector(state=>state.userStore.token)
    const [postImage, setPostImage] =useState(null)
    const [invalidImage, setinvalidImage]=useState()
    const [loading, setLoading]=useState(false)
    const [errorMessage, setErrorMessage]=useState(false)
    const [type, setType]=useState('')


    const handleSubmit =async(e)=>{
        if(type === 'image'){
            return handleimageSubmit(e)
        }
        handleVedioSubmit(e)
    }
    const handleimageSubmit =async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("img", postImage)
        formData.append("caption",  capRef.current.value )
        formData.append("userId" , userId)
        formData.append("type",type)
        try {
            setLoading(true)
            const fetchData = await fetch("https://gaoney.online/post",{
                headers :{
                    Authorization : 'Bearer ' + userToken
                },
                method : "POST",
                body : formData
            })
            const data = await fetchData.json()
            setLoading(false)
            console.log(data)
            // navigate("/")
        } catch (error) {
            console.log(error)
        }

    }
    const handleVedioSubmit =async(e)=>{
        e.preventDefault()
        const formData = new FormData()
        console.log(postImage)
        formData.append("avater", postImage)
        formData.append("caption",  capRef.current.value )
        formData.append("userId" , userId)
        formData.append('type',type)
        try {
            setLoading(true)
            const fetchData = await fetch("https://gaoney.online/post/video",{
                headers :{
                    Authorization : 'Bearer ' + userToken
                },
                method : "POST",
                body : formData
            })
            const data = await fetchData.json()
            console.log(data)
            setLoading(false)
            // navigate("/")
        } catch (error) {
            navigate("/error")
        }

    }
    const handleImageClick =()=>{
        setPostImage(null)
        setType('image')
        imgRef.current.click()
    }
    const handleVideoClick =()=>{
        setPostImage(null)
        setType('video')
        videoRef.current.click()
    }
    // const handlefileUpload =async(e)=>{
    //     // const fileReader = new FileReader()
    //     // fileReader.readAsDataURL(e.target.files[0])
    //     // fileReader.onload=()=>{
    //     //     setPostImage(fileReader.result)
    //     // }
    //     // fileReader.onerror=(error)=>{
    //     //     console.log(error)
    //     // }
    //     // const base64 = await convertToBase64(file)
    //     // setPostImage({...postImage, myFile : base64})
    //     const file = e.target.files[0]
    //     const url = URL.createObjectURL(file)
    //     const img = new Image()
    //     img.src  = url
    //     img.onload = function(){
    //         const canvas = document.createElement('canvas')
    //         canvas.toBlob((blob)=>{
    //             const fr = new FileReader()
    //             fr.readAsDataURL(url)
    //             fr.addEventListener('load',()=>{
    //                 const dataUrl = fr.result
    //                 setPostImage(dataUrl)
    //                 console.log(fr)
    //             })
    //         },'image/webp',0.1)
    //     }
    // }
    let reader = new FileReader(); 
    const handleInputChange = (event) => {
        const imageFile = event.target.files[0];
        console.log(event.target.files[0])
 
              if (!imageFile) {
               setinvalidImage('Please select image.');
               setErrorMessage(true)
                return false;
              }
          
              if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
               setinvalidImage('Please select valid image JPG,JPEG,PNG');
               setErrorMessage(true)
                return false;
              }
              reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
 
 //------------- Resize img code ----------------------------------
                 let canvas = document.createElement('canvas');
                 let ctx = canvas.getContext("2d");
                 ctx.drawImage(img, 0, 0);
 
                 let MAX_WIDTH = 437;
                 let MAX_HEIGHT = 437;
                 let width = img.width;
                 let height = img.height;
 
                 if (width > height) {
                   if (width > MAX_WIDTH) {
                     height *= MAX_WIDTH / width;
                     width = MAX_WIDTH;
                   }
                 } else {
                   if (height > MAX_HEIGHT) {
                     width *= MAX_HEIGHT / height;
                     height = MAX_HEIGHT;
                   }
                 }
                 canvas.width = width;
                 canvas.height = height;
                //  let ctx = canvas.getContext("2d");
                 ctx.drawImage(img, 0, 0, width, height);
                 ctx.canvas.toBlob((blob) => {
                //    const file = new File([blob], imageFilname, {
                //        type: 'image/jpeg',
                //        lastModified: Date.now()
                //    });
                                const fr = new FileReader()
                            fr.readAsDataURL(blob)
                            fr.addEventListener('load',()=>{
                                const dataUrl = fr.result
                                setPostImage(dataUrl)
                                console.log(fr,blob)
                            })
                
                 }, 'image/png', 0.1);
               setinvalidImage(null)
               setErrorMessage(false)
               };
                img.onerror = () => {
                      setinvalidImage('Invalid image content.');
                      setErrorMessage(true)
                  return false;
                };
                //debugger
                img.src = e.target.result;
                console.log(e.target.result)
              };
              reader.readAsDataURL(imageFile);
             
 
      };
    const handleVideo = (event)=>{
        const imageFile = event.target.files[0];
        console.log(event.target.files[0].size)
 
        if (!imageFile) {
            setinvalidImage('Please select Video.');
            setErrorMessage(true)
            return false;
        }
    
        if (!imageFile.name.match(/\.(mp4|webm)$/)) {
            setinvalidImage('Please select valid video file');
            setErrorMessage(true)
            return false;
        }
        if(imageFile.size > 10000000){
            setinvalidImage('max size is 10mb');
            setErrorMessage(true)
            return false;
        }
        
        setPostImage(imageFile)
           
        
    }
    
    return(
        <div className="w-full h-auto flex-col items-center mt-20">
            {
                loading && <div className="w-full flex justify-center text-xl my-2">Loading...</div>
            }
            <div>{invalidImage}</div>
            <form className="w-auto flex flex-col mx-4" method ="POST" onSubmit={handleSubmit} >
                <div className="flex flex-col items-center">
                    <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload image</label>
                    <div className="h-auto w-full lg:w-1/3 px-2 flex justify-center">
                    {
                        type === 'image' ?(
                             postImage && <img src={postImage} alt="url" className="max-h-80" />
                        
                        ):(
                            postImage && <div className="w-full min-h-64 px-3 m-2 border-2 border-gray-200 bg-gray-100 flex justify-center items-center text-xl">Preview not Available </div>
                        )
                    
                    }
                    </div>
                    <div className="w-full flex gap-2">
                        <div className="h-auto w-full lg:w-1/3 flex justify-center">
                            <input ref={imgRef} onChange={handleInputChange}  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 hidden" id="file_input" type="file" accept=".jpg, .png, .jpeg" />
                            <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={handleImageClick} >Select Image</button>
                        </div>
                        <div className="h-auto w-full lg:w-1/3 flex justify-center">
                            <input ref={videoRef} onChange={handleVideo}  className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 hidden" id="file_input" type="file" accept=".mp4, .webm," />
                            <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={handleVideoClick} >Select Video</button>
                        </div>
                    </div>
                </div>
                <div className="h-auto w-full  flex flex-col items-center">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white lg:w-1/3">Caption</label>
                    <textarea ref={capRef} id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 lg:w-1/3 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none" placeholder="Write your thoughts here..."></textarea>
                </div>
                <div className="flex justify-end itmes-center w-full lg:w-2/3">
                    <button className="py-3 px-6 rounded text-lg text-white bg-blue-700 font-normal mt-3 border" disabled={errorMessage}>Post</button>
                </div>
            </form>
            
            
        </div>
    )
}

export default Post
