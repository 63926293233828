import Allroute from "./Router/Allroute";
import Header from "./componet/Header";
import "./App.css"
import { useEffect} from "react";
import { useDispatch } from "react-redux";
import { getUserData, getUserId } from "./store/userSlice";


function App() {
const  dispatch = useDispatch()
const userSession = sessionStorage.getItem("tokenId")
  useEffect(()=>{
    if(userSession){
      const userId = userSession.split("::")[1]
      const token = userSession.split("::")[0]
      dispatch(getUserId({userId : userId, token : token}))
  }
  },[dispatch, userSession])


  useEffect(()=>{
    const userSession = sessionStorage.getItem("tokenId")
    if(userSession){
      const userId = userSession.split("::")[1]
      try{
        const fetchData = async()=>{
          const getData = await fetch("https://gaoney.online/" + userId)
          const data = await getData.json()
          dispatch(getUserData(data))
        }
        if(userId){
          fetchData()
        }
  
      }catch(error){
        console.log(error)
      }

    }
    
        
    
},[dispatch])
  
  return (
    <div className="w-full p-0 m-0">
      <Header className="z-20"   />
      <Allroute className="z-10  "  />
    </div>
  );
}

export default App;
