import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CardSkeleton =({card})=>{
    return(
            Array(card).fill(0).map((item, index)=>(
                <div className='w-full lg:w-1/3 flex flex-col h-auto mb-3 ' key={index}>
                    <div className='w-full flex items-center'>
                        <Skeleton circle width={50} height={50} />
                        <div className='flex-1 text-3xl ml-2'>
                            <Skeleton  />
                        </div>
                    </div>
                    <div className='w-full h-56'>
                        <Skeleton height={200}/>
                    </div>
                </div>
            ))
    )
}

export default CardSkeleton