import {  useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaRegEye, FaEyeSlash } from "react-icons/fa";

const SignUp =()=>{
    const fname = useRef()
    const params = useParams()
    console.log(params)
    const sname = useRef()
    const country = useRef()
    const dateOfBirth = useRef()
    const email = useRef()
    const pass = useRef()
    const cPass = useRef()
    const navigate = useNavigate()
    const [errorState, setErrorState]=useState([])
    const [passwordError, setPasswordError]=useState(null)
    const [emailError, setEmilError]=useState(null)
    const [state, setState]=useState(true)
    const [seenState, setSeenState]=useState(false)
    const [loading, setLoading]=useState(false)

    const handleForm = async(e)=>{
        e.preventDefault()
        setLoading(true)
        fetch('https://gaoney.online/signup',{
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify(
                {
                    fname : fname.current.value,
                    sname : sname.current.value,
                    country: country.current.value,
                    dateOfBirth : dateOfBirth.current.value,
                    email : email.current.value,
                    pass : pass.current.value,
                    cpass : cPass.current.value,
                    referralId : params.referralId
                }
            )
        }).then(fetchData=> fetchData.json()).then(data =>{
            setLoading(false)
            console.log(data)
            if(data.statusCode === 422){
                const passError = data.data.find(state=>state.path === "pass")
                const emalError =data.data.find(state=>state.path === "email")
                setEmilError(emalError)
                setPasswordError(passError)
                setErrorState(data.data)
                console.log(errorState)
            }else if(data.statusCode === 500){
                navigate("/error")
                
            }else{
                navigate(`/getverify/${email.current.value}`)
            }
        }).catch(err=>{
            navigate("/error")
        })
    }
    
    return(
        <div className="w-full h-full bg-white flex justify-center items-center mt-24">
            <div className="w-full lg:w-3/5  bg-white rounded-lg shadow-lg p-4 mt-3">
                <form method="POST" onSubmit={handleForm}>
                {
                        loading && <div className="w-full flex justify-center text-xl my-2">Loading...</div>
                    }
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
                            <input ref={fname}  type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
                        </div>
                        <div>
                            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last name</label>
                            <input ref={sname} type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
                        </div>
                        <div>
                            <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nationality</label>
                            <input ref={country} type="text" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />

                        </div>  
                        {/* <div>
                            <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company</label>
                            <input type="" id="company" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" required />
                        </div>   */}
                        
                        <div>
                            <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date Of Birth</label>
                            <input ref={dateOfBirth} type="date" id="visitors" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                        </div>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email address</label>
                        <input ref={email} type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="abc@mail.com" />
                    </div> 
                    {
                        emailError ? (
                            <div className="text-xm text-red-500 mb-3 -mt-3 pl-2">
                                {
                                    emailError.msg
                                }
                            </div>
                        ):""
                    }
                    
                    <div className="w-full flex justify-right border">
                        <button onClick={()=>setSeenState(!seenState)}>
                            {
                                seenState ?  (
                                    <FaEyeSlash />
                                ) :(
                                    <FaRegEye />
                                )
                            }
                        </button>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input ref={pass} type={seenState ? "password" :"text"} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="•••••••••" />
                    </div> 
                    <div className="mb-6">
                        <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                        <input ref={cPass} type={seenState ? "password" :"text"} id="confirm_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="•••••••••" />
                    </div> 
                    <div className="w-full px-4">
                        {
                            passwordError ? (
                                <div className="text-red-500 text-xm font-normal mb-3 -mt-2">
                                    <p>Password must be more than 8</p>
                                    <p>Password must have at least 1 alphabet</p>
                                    <p>Password must have at least 1 Number</p>
                                    <p>There must not be special character e.g @ % $ *#</p>
                                </div>
                            ):""
                            
                        }
                    </div>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                        <input  id="remember" onClick={()=>setState(!state)} type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
                        </div>
                        <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <Link to="/termsandcondition" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</Link>.</label>
                    </div>
                    <button type="submit" disabled={state} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                   
                </form>

            </div>
        </div>
    )
}


export default SignUp