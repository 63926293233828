import {  useRef, useState} from "react"
import { Link, useNavigate } from "react-router-dom"

const Login =()=>{
    const email = useRef()
    const pass = useRef()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage]=useState(null)
    const [loading, setLoading]=useState(false)


    const handleSubmit= async(e) =>{
        e.preventDefault()
        try {
            setLoading(true)
            const fetchData = await fetch('https://gaoney.online/login',{
            method : "POST",
            headers : {
                'Content-Type' : "application/json"
            },
            body : JSON.stringify({
                email : email.current.value,
                pass : pass.current.value
            })
            })
            setLoading(true)
            const data = await fetchData.json()
            setLoading(false)
            if(data.statusCode === 401){
                return setErrorMessage("Your Email or Password is Incorrect")
            }
            if(data.statusCode === 500){
                navigate("/error")
            }else{
                if(data.email_verify){
                    sessionStorage.setItem("tokenId", `${data.token}::${data.userId}`)
                    navigate("/")
                    window.location.reload()

                }else{
                    console.log(email.current.value)
                    navigate(`/getverify/${email.current.value}`)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <div className="w-full h-full bg-white flex justify-center items-center mt-24">
            <div className="w-full lg:w-3/5  bg-white rounded-lg shadow-lg p-4 mt-3">
                <form method="POST" onSubmit={handleSubmit}>
                    {
                        errorMessage ? (
                            <div className="py-2 px-3 my-2 w-full flex justify-start border border-red-500 bg-red-100">
                                { errorMessage}
                            </div>
                        ): ""
                    }
                    {
                        loading && <div className="w-full flex justify-center text-xl my-2">Loading...</div>
                    }
                    <div >
                        <label htmlFor="input-group-1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                        <div className="relative mb-6">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                    <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                                    <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                                </svg>
                            </div>
                            <input ref={email} type="email" id="input-group-1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@email.com" />
                        </div>
                        <label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <div className="flex">
                            <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                </svg>
                            </span>
                            <input ref={pass} type="password" id="website-admin" className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="password" />
                        </div>
                        <div className="w-full flex justify-end my-2">
                            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Sign In
                                
                            </button>

                        </div>


                    </div>

                </form>
                <div className="w-full flex flex-col">
                    <div>
                        <Link to="/forgetpass">
                            <p className="ml-2 text-blue-700">Forget password</p>
                        </Link>
                    </div>
                    <div className="flex w-full ">
                        <p>If you do not have an exsiting account </p>
                            <Link to="/signup" className="ml-4 text-blue-700">
                                Sign Up
                                
                                </Link>
                    </div>
                </div>

            </div>

        </div>
    )
}


export default Login