import { Link, useNavigate } from "react-router-dom"
import {  useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import {  getUserData, removeUser } from "../store/userSlice";

const Header =()=>{
    const userData = useSelector(state=>state.userStore.userData)
    const userId = useSelector(state=>state.userStore.userId)
    const dispatch = useDispatch()
    const  [profile, setProfile]=useState(false)
    const  [menu, setMenu]=useState(false)
    const  [user, setUser]=useState(false)
    const [profileName , setProfileName] = useState("")
    const [profileEmail , setprofileEmail] = useState("")
    const [profileImg, setProfileImg]=useState("")
    // const [activeUser, setActiveUsers]=useState('')
    const userToken = useSelector(state=>state.userStore.token)
    const navigate = useNavigate()

    
    useEffect(()=>{
        try {
            const getData =async()=>{
              const fetchData = await fetch(`https://gaoney.online/${userId}`,{
                headers : {
                    Authorization : 'Bearer ' + userToken
                }
            })
              const data = await fetchData.json()
              dispatch(getUserData(data))
            }
            getData()
          } catch (error) {
            navigate("/error")
          }
    },[userId,userToken, dispatch, navigate])
    
    useEffect(()=>{
        if(userId){
            setUser(true)
            setProfileName(userData?.user?.fname)
            setprofileEmail(userData?.user?.email)
            setProfileImg(userData?.user?.profile_img)
        }
    },[userData,userId])

    const handleSignOut =()=>{
        setUser(false)
        setProfile(!profile)
        sessionStorage.removeItem("tokenId")
        dispatch(removeUser(null))
        window.location.reload()
    }
    return(
        <div>
            <nav className="bg-white border-gray-200 dark:bg-gray-900 border-b-2 fixed top-0 right-0 left-0 text-lg lg:text-xl flex flex-col">
                <div className="w-full flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Gaoney</span>
                    </Link>
                    <div className="flex items-center md:order-2  md:space-x-0 rtl:space-x-reverse lg:w-48 justify-center">
                        {/* <Link to="/search">
                            <IoSearchOutline  className="h-8 w-8 lg:mx-4 mx-2"/>
                        </Link> */}
                        {
                            user ?(
                                
                                <button type="button" onClick={()=>setProfile(!profile)} className="flex text-sm bg-gray-200 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 mx-2" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                                    <span className="sr-only">Open user menu</span>
                                    {
                                        profileImg ?<img className="w-8 h-8 rounded-full border" src={ profileImg} alt="user" /> : <CiUser className="w-8 h-8"/>
                                    }
                                    
                                </button>
                            ):(
                                <Link to="/login">
                                    <button className=" relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
                                        <span className=" relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Sign In
                                        </span>
                                    </button>
                                </Link>
                            )
                        }
                        {/* <!-- Dropdown menu --> */}
                        <div className={profile ? "z-50 bg-white fixed bg-white lg:top-16 top-20 right-0 text-base list-none divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" : "z-50 bg-white hidden absolute top-14 right-6 text-base list-none divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"} id="user-dropdown">
                            <div className="px-4 py-3">
                            <span className="block text-sm text-gray-900 dark:text-white">{profileName}</span>
                            <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">{profileEmail}</span>
                            </div>
                            <ul className="py-2" aria-labelledby="user-menu-button">
                            <li>
                                <Link to={`/profile/${userId}`} onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Profile</Link>
                            </li>
                            <li>
                                <Link to="/post" onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Post</Link>
                            </li>
                            <li>
                                <Link to="/anonlymous" onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Anonymous</Link>
                            </li>
                            <li>
                                <Link to="/tap-count" onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Tap Count</Link>
                            </li>
                            <li>
                                <Link to="/dashboard" onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Dashboard</Link>
                            </li>
                            <li>
                                <Link to="/aboutus" onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">About Us</Link>
                            </li>
                            <li>
                                <Link to="#"  onClick={()=>setProfile(!profile)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Privacy policy</Link>
                            </li>
                            <li>
                                <button  onClick={handleSignOut} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</button>
                            </li>
                            </ul>
                        </div>
                        <button data-collapse-toggle="navbar-user" type="button" onClick={()=>setMenu(!menu)} className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                    </div>
                    <div className={menu ? "items-center justify-between w-full md:flex md:w-auto md:order-1" :"items-center hidden justify-between w-full md:flex md:w-auto md:order-1"} id="navbar-user">
                        <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <Link to="/"  onClick={()=>setMenu(!menu)} className="block py-2 px-3 text-gray-900 bg-blue-700 rounded md:bg-transparent md:focus:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">Home</Link>
                            </li>
                            <li>
                                <Link to="/quiz" onClick={()=>setMenu(!menu)} className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 focus:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Quiz</Link>
                            </li>
                            <li>
                                <Link to="/mission" onClick={()=>setMenu(!menu)} className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 focus:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Mission</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </nav>
            


        </div>
    )
}


export default Header