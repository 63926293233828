import { Route, Routes } from "react-router-dom"
import HomePage from "../pages/homePage/HomePage"
import QuizPage from "../pages/QuizPage/QuizPage"
import QuizingPage from "../pages/QuizPage/QuizingPage"
import Login from "../pages/Sign/Login"
import SignUp from "../pages/Sign/SignUp"
import ForgetPass from "../pages/Sign/ForgetPass"
import ResetPass from "../pages/Sign/ResetPass"
import VerifyEmail from "../pages/Sign/verifyEmail"
import AboutUs from "../pages/profile/AboutUs"
import DashBoard from "../pages/profile/DashBoard"
import ConvertPage from "../pages/profile/ConvertPage"
import Profile from "../pages/profile/Profle"
import Post from "../pages/Post/Post"
import { useEffect} from "react"
import { useDispatch, useSelector} from "react-redux"
import {  getUserId } from "../store/userSlice"
import Anomalous from "../pages/Post/anomalous"
import SendAnomalous from "../pages/Post/sendAnomalous"
import PageNotFound from "../pages/utli/PagenotFound"
import Something from "../pages/utli/somthing"
import PrivacyPolicy from "../pages/profile/privacyPolicy"
import TermsAndCondition from "../pages/profile/termsAndCondition"
import GetVerify from "../pages/Sign/getVerify"
import ForgetFloat from "../pages/Sign/ForgetFloat"
import MissionPage from "../pages/mission/missionPage"
import TapCount from "../pages/Post/TapCount"
// import AdminPage from "../pages/profile/admin"


const Allroute =()=>{
    const  dispatch = useDispatch()
    const userId = useSelector(state=>state.userStore.userId)
    
    useEffect(()=>{
      const userSession = sessionStorage.getItem("tokenId")
      if(userSession){
        const userId = userSession.split("::")[1]
        const token = userSession.split("::")[0]
        dispatch(getUserId({userId : userId, token : token}))
        
      }
    })
    return(
        <Routes>
            <Route path="/" element={<HomePage /> } />
            <Route path="/quiz/:category/:qus_no/:difficulty/:title" element={<QuizingPage /> } />
            <Route path="/quiz" element={<QuizPage /> } />
            <Route path="/signup/:referralId" element={<SignUp />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgetpass" element={<ForgetPass />} />
            {/* <Route path="/resetpassword" element={<ResetPass /> } /> */}
            <Route path="/forgettenemailsent" element={<ForgetFloat />}  />
            <Route path="/aboutus" element={userId ? <AboutUs /> : <Login />} />
            <Route path="/dashboard" element={userId ? <DashBoard /> : <Login />} />
            <Route path="/dashboard/convert" element={userId ? <ConvertPage /> : <Login />} />
            <Route path="/profile/:id" element={userId ? <Profile /> : <Login />} />
            <Route path="/post" element={userId ? <Post /> : <Login />} />
            <Route path="/verifyEmail/:sendrandom/:userId" element={ <VerifyEmail /> } />
            <Route path="/anonlymous/:id" element={<SendAnomalous /> } />
            <Route path="/anonlymous" element={userId ? <Anomalous /> : <Login />} />
            <Route path="/tap-count" element={userId ? <TapCount /> : <Login />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/getverify/:email" element={<GetVerify />}  />
            <Route path="/termsandcondition" element={<TermsAndCondition />} />
            <Route path="/changepassword/:userId/:token" element={<ResetPass />} />
            <Route path="/mission" element={userId ? <MissionPage /> : <Login />} />
            {/* <Route path="/admin" element={<AdminPage />} /> */}
            <Route path="/error" element={<Something />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default Allroute