const PrivacyPolicy =()=>{
    return (
        <div  className="w-full mt-20">
            <div className="w-full">
                
            </div>
        </div>
    )
}

export default PrivacyPolicy