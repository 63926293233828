import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import HomeCard from "./HomeCard"
import CardSkeleton from "../../componet/cardSkeleton"
import ReactGA from "react-ga4"
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" })
const HomePage =()=>{
    const [state, setState]=useState([])
    const [isloading, setIsLoading]=useState(false)
    const [hasMore, setHasmore]=useState(true)
    const [addpage, setAddPage]=useState(null)
    const [dataLength, setDataLength]=useState(0)
    useEffect(()=>{
        const callApi =async()=>{
            try{
                const fetchData = await fetch("https://gaoney.online/allpost?page=1&limit=5")
                setIsLoading(true)
                const data = await fetchData.json()
                console.log(data)
                setIsLoading(false)
                data?.result?.sort((a, b)=> b.createdAt.localeCompare(a.createdAt))
                setDataLength(data.postCount)
                if(data.next){
                    setHasmore(true)
                    setAddPage(data.next.page)
                }else{
                    setHasmore(false)
                }
                const sortdata = data.result.sort(()=>Math.random()-0.5)
                setState(sortdata)
            }catch(error) {
                console.log(error)
            }
            
        }
        callApi()

    },[])
    const fetchMoreData =async()=>{
        console.log(addpage)
        const fetchData = await fetch(`https://gaoney.online/allpost?page=${addpage}&limit=5`)
        const data = await fetchData.json()
        if(data.next){
            setAddPage(data.next.page)
            setHasmore(true)
            setDataLength(data.postCount)
        }else{
            setHasmore(false)
        }
        const sortdata = data.result.sort(()=>Math.random()-0.5)
        setState([...state, ...sortdata])
    }
    
    return(
        <div  className="w-full flex justify-center mt-20 px-2">
            {isloading && <CardSkeleton card={3}/>}
            <InfiniteScroll 
            className="max-w-full lg:max-w-1/3 px-4"
            dataLength={dataLength} 
            next={fetchMoreData} 
            hasMore={hasMore}
            loader={<CardSkeleton card={3}/>} >
                {
                    state?.map((tate, index)=>(
                        <HomeCard key={index} data={tate} />

                    )) 
                }
            </InfiniteScroll>
        </div>
    )
}

export default HomePage