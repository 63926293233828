import { useEffect, useState } from "react"
import QuizCard from "./QuiCard"
import { useSelector } from "react-redux"

const QuizPage =()=>{
    const [state, setState]=useState([])
    const [userTrue, setUserTrue]=useState(null)
    const [exist, setExist]=useState(false)
    const [loading, setLoading]=useState(false)
    const userId = useSelector(state=>state.userStore.userId)
    const userToken = useSelector(state=>state.userStore.userToken)

    
    useEffect(()=>{
        
        try {

            const getQuiz=async()=>{
                const fetchQuiz = await fetch(`https://gaoney.online/allquiz/${userId}`,{
                    // headers : {
                    //     Authorization : 'Bearer ' + userToken
                    // }
                })
                const {data} = await fetchQuiz.json()
                console.log(data)
                setUserTrue(data)
            }
            getQuiz()
        } catch (error) {
            
        }
        
    },[userId,userToken])

    useEffect(()=>{
        const navigateQuiz =[
            {
                category : 9,
                difficulty :  'easy',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 9,
                difficulty :  'medium',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 9,
                difficulty :  'hard',
                qus_no : 10,
                name : 'General Knowledge'
            },
            {
                category : 10,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 10,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 10,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Books'
            },
            {
                category : 11,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Film'
            },
            {
                category : 11,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 11,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 11,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Music'
            },
            {
                category : 13,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 13,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 13,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Musical $ Thearter'
            },
            {
                category : 14,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 14,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 14,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Television'
            },
            {
                category : 15,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 15,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 15,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Video Game'
            },
            {
                category : 16,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 16,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 16,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Board Game'
            },
            {
                category : 17,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 17,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 17,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science & Nature'
            },
            {
                category : 18,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 18,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 18,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Computer'
            },
            {
                category : 19,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 19,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 19,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Mathematics'
            },
            {
                category : 20,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 20,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 20,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Mythology'
            },
            {
                category : 21,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 21,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 21,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Sports'
            },
            {
                category : 22,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 22,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 22,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Geography'
            },
            {
                category : 23,
                difficulty :  'easy',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 23,
                difficulty :  'medium',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 23,
                difficulty :  'hard',
                qus_no : 10,
                name : 'History'
            },
            {
                category : 24,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 24,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 24,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Politics'
            },
            {
                category : 25,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 25,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 25,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Art'
            },
            {
                category : 26,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 26,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 26,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Celebrities'
            },
            {
                category : 27,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 27,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 27,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Animals'
            },
            {
                category : 28,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 28,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 28,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Vehicles'
            },
            {
                category : 30,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 30,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 30,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Science: Gadget'
            },
            {
                category : 29,
                difficulty :  'easy',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
            {
                category : 29,
                difficulty :  'medium',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
            {
                category : 29,
                difficulty :  'hard',
                qus_no : 10,
                name : 'Entertainment: Comics'
            },
        ]
         if(!userTrue?.userId){
            setExist(true)
        }else{
            setExist(false)
        
        const arr = []
        if(userTrue){
            if(userTrue.model.length === 0){
                arr.push(...navigateQuiz)
            }else{
                for (let i = 0; i < navigateQuiz.length; i++) {
                    const element = navigateQuiz[i];
                    const filterArray = userTrue.model.find(state=> state.title !== element.name)
                    const filterEqual = userTrue.model.find(state=> state.title === element.name)
                    const filterDifficulty = filterEqual?.difficulty.find(state=>state !== element?.difficulty)
                   
                    if(filterArray){
                        arr.push(element)
                    }else if(filterDifficulty){
                        arr.push(element)
                    }
                }
            }
            
            arr.sort((a, b)=> a.name.localeCompare(b.name))
            setState(arr)
        }
        }
    },[userTrue])
    const handleQuiz =async()=>{
        const formData =  new FormData()
        formData.append("userId", userId)
        try {
            setLoading(true)
            const postQuiz = await fetch("https://gaoney.online/start-quiz",{
                method : "POST",
                headers : {
                    Authorization : 'Bearer ' + userToken
                },
                body : formData
            })
            setLoading(true)
            const data = await postQuiz.json()
            
            console.log(data)
            const getSplit = data?.createdAt?.split("T")
            const getSplitTime = getSplit[0].split("-")
            const realDay = Number(getSplitTime[2])+5
            const realMonth = Number(getSplitTime[1])
            const newCreated = `${getSplitTime[0]}-${realMonth}-${realDay}T${getSplit[1]}`
            const updateTime = await fetch(`https://gaoney.online/updatequiztime/${userId}/${newCreated}`,{
                headers : {
                    Authorization : 'Bearer ' + userToken
                }
            })
            await updateTime.json()
            setLoading(false)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    
    return(
        <>
        {
            exist ? (
                <div className="w-full mt-20 flex justify-center px-2">
                    <div className="w-full lg:w-2/3 flex flex-col">
                    {
                        loading && <div className="text-2xl w-full flex justify-center my-2">Loading...</div>
                    }
                        <p>Test Your Knowledge and Earn Rewards</p>
                        <p>The Gaoney Quiz is an exciting features that challenges yor Knowledgeand rewards your effort! Here's how it works:</p>
                        <p><span>Quiz Duration:</span>Each quiz is available for 5 days from the date of publication.</p>
                        <p><span>Completion Deadline:</span>Finish the quiz within the 5-days timeframe to earn points</p>
                        <p>Points System</p>
                        <ul>
                            <li>500 Points per Quiz: Complete each quiz before the deadline to earn 500 points</li>
                            <li>10,000 Bonus Points: Finish all quizzes within the allotted time to recieve a 10000-point bonus!</li>
                        </ul>
                        <p>Quiz Rules:</p>
                        <ul>
                            <li>Quizzes are available for a limited time only (5 days)</li>
                            <li>Users must complete the quiz within the deadline to earn  points</li>
                            <li>500 points are awarded for each completed quiz</li>
                            <li>A 10,000-point bonus is awarded for completing all quiz within the timeframe</li>
                        </ul>
                        <p>Why Take the Quiz?</p>
                        <li>Test Your Knowledge: Assess your understanding of various topics and identify areas for improvement</li>
                        <li>Each Points: Accumulate points foor each completed quiz and redeem them for Gaoney Coin</li>
                        <li>Bonus Incentives: Strive to complete all quizzes within the deadline to earn the 10,000-points bonus</li>
                        <div className="w-full flex justify-end p-4">
                            <button onClick={handleQuiz} className="w-auto py-2 px-3 bg-blue-500 rounded-lg text-white">Get Started</button>
                        </div>
                    </div>
                </div>
            ):(
                <div className="w-full min-h-screen mt-20 lg:px-12 flex lg:justify-evenly justify-center flex-wrap">
                    {
                        
                        state.map((state, index)=>(
                            
                            <QuizCard key={index} data={state} />
                        ))
                    }
                
                </div>

            )
        }
        </>
    )
}


export default QuizPage