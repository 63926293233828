import { useEffect, useRef, useState} from "react"
import { useSelector } from "react-redux"
import g_coin from "../../images/g_coin.png"
const ConvertPage = ()=>{
    const amountRef = useRef(0)
    const [state, setState]=useState(0)
    const [dashboard, setDashboard]=useState({})
    const [amountDash, setAmountDash]=useState({})
    const userToken =  useSelector(state=>state.userStore.token)
    const userId = useSelector(state=>state.userStore.userId)
    const [dashState, setDashState]=useState({
        title : "Quiz Point",
        convertion_rate : 0.001,
        expected_title : "quiz_point"
    })
    const converDifferentPoint = [
        {
            title : "Quiz Point",
            convertion_rate : 0.001,
            expected_title : "quiz_point"
        },
        {
            title : "Referral Point",
            convertion_rate : 0.003,
            expected_title : "referral_point"
        },
        {
            title : "Tap Point",
            convertion_rate : 0.0001,
            expected_title : "tap_point"
        },
        {
            title : "Anonlymous & Post",
            convertion_rate : 0.002,
            expected_title : "anonlymous_post"
        }
    ]
    
    useEffect(()=>{
        try {
            const getDashBoard = async()=>{
                const fetchData = await fetch(`https://gaoney.online/dashboard/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const {data} = await fetchData.json()
                console.log(data)
                setDashboard(data)
                setAmountDash({
                    title : "Quiz Point",
                    amount : data?.quiz_point
                })
            }
            getDashBoard()
        } catch (error) {
            console.log(error)
        }
    },[userId, userToken])
    const handleChange =(e)=>{
        console.log(e.target.value)
        const convertPage = converDifferentPoint.find(convert=>convert.title === e.target.value)
        setDashState(convertPage)
        if(convertPage.expected_title === "quiz_point"){
            setAmountDash({
                title : convertPage.title,
                amount : dashboard.quiz_point
            })
        }else if(convertPage.expected_title === "referral_point"){
            setAmountDash({
                title : convertPage.title,
                amount : dashboard.referral_point
            })
        }else if(convertPage.expected_title === "tap_point"){
            setAmountDash({
                title : convertPage.title,
                amount : dashboard.tap_point
            })
        }else if(convertPage.expected_title === "anonlymous_post"){
            setAmountDash({
                title : convertPage.title,
                amount : dashboard.anonlymous_post
            })
        }

    }
    const handleClick=async()=>{
        
        const amount = state * dashState.convertion_rate
       const formData = new FormData()
       formData.append("title",dashState.expected_title)
       formData.append("userId", userId)
       formData.append("amount", amount)
       formData.append("convert_amount", state)
       console.log(amount,Number(state),dashState)
       const sendData = await fetch("http://localhost:8080/update-dashboard",{
        headers : {
            Authorization : 'Bearer ' + userToken,
            "content-type" : "application/json"
        },
        method : "POST",
        body : JSON.stringify({
            title :dashState.expected_title,
            "userId": userId,
            "amount" : amount,
            "convert_amount": state

        })
       })
       const data = await sendData.json()
       console.log(data)
    }
    const toDisable=()=>{
        let isdisable ;
        if(dashState.expected_title === "quiz_point"){
            if(Number(state) <= dashboard.quiz_point  ){
                isdisable = false
            }else{
                isdisable =true
            }
        }else if(dashState.expected_title === "referral_point"){
            if( Number(state) <= dashboard.referral_point){
                isdisable = false
            }else{
                isdisable =true
            }
        }else if(dashState.expected_title === "tap_point"){
            if(Number(state) <= dashboard.tap_point){
                isdisable = false
            }else{
                isdisable =true
            }
        }else if(dashState.expected_title === "anonlymous_post"){
            if(Number(state) <= dashboard.anonlymous_post){

                isdisable = false
            }else{
                isdisable =true
            }
        }
        if(!isdisable){
            if(Number(state) >= 1000){
                isdisable=false
            }else{
                isdisable =true
            }
        }else {
            isdisable = true
        }
        return isdisable 
    }
    return(
        <div className="mt-20 w-full flex justify-center">
                <div className="w-full lg:w-1/3 flex flex-col items-center">
                    <div className="py-4 px-3 w-full">
                        <h2 className="text-2xl font-bold text-black">Convert To Gaoney Slot</h2>
                        <p classname="text-lg text-gary-300">Enter the amount of point to convert to G-slot </p>
                    </div>
                    <div className="w-full text-xl font-bold px-3 py-2  ">{`${amountDash.title} (${amountDash.amount}) `}</div>
                    <div className="w-full flex justify-between px-3 ">
                        <select onChange={handleChange} id="small" className="block w-full p-3 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none">
                            {
                                converDifferentPoint.map((convert, index)=>(
                                    <option key={index}>{convert.title}</option>
                                ))
                            }
                        </select>
                        <div></div>
                    </div>
                    <div className="w-full py-3 px-4 ">
                        <div className="w-full flex justify-between border rounded-lg py-3 px-3">
                            <h3>Amount :</h3>
                            <div className="w-3/4 ">
                                <input type="number" onChange={()=>setState(Number(amountRef.current.value))} className="w-full h-full flex text-right outline-none" placeholder="0" ref={amountRef} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col items-center px-4 my-2">
                        <div className="w-full flex justify-between mb-2">
                            <span>Convertion rate : </span>
                            <span>{dashState.convertion_rate || 0.001}</span>
                        </div>
                        <div className="w-full flex justify-between mb-2">
                            <span>Charges : </span>
                            <span>0.00</span>
                        </div>
                        <div className="w-full flex justify-between ">
                            <span>Amount to convert :  </span>
                            <span>{state} X {dashState.convertion_rate || 0.001}</span>
                        </div>
                    </div>
                    <div className="w-full py-3 px-4 ">
                    <div className="w-full flex justify-start items-center">
                        <div className="">
                            <img src={g_coin} alt="" className="w-8 h-8 rounded-full m-2" />
                        </div>
                        <div>G-slot</div>
                    </div>
                        <div className="w-full flex justify-between border rounded-lg py-3 px-3">
                            <h3>Amount :</h3>
                            <div className="w-2/4 ">
                                <span className="w-full h-full flex justify-end" >
                                    { Math.floor(state * (dashState.convertion_rate || 0.001))}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full pr-4 py-3 flex justify-end ">
                        <button onClick={handleClick} disabled={toDisable()} className={`py-2 px-3 ${toDisable() ? 'bg-blue-100' : 'bg-blue-600'} rounded-lg text-white cursor-pointer mr-2`}>Convert</button>
                    </div>
                </div>
            </div>
    )
}
export default ConvertPage