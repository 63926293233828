import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import ProfilePostCard from "./profilePostCard"
import CardSkeleton from "../../componet/cardSkeleton"
import InfiniteScroll from "react-infinite-scroll-component"


const ProfilePost =({id})=>{
    const [state, setState]=useState([])
    const useId = useSelector(state=>state.userStore.userId)
    const userToken = useSelector(state=>state.userStore.token)
    const navigate = useNavigate()
    const [hasMore, setHasmore]=useState(true)
    const [addpage, setAddPage]=useState(null)
    const [dataLength, setDataLength]=useState(0)
    const [isloading, setIsLoading]=useState(false)
    useEffect(()=>{
        try {
            const gethData =async()=>{
                const fetchData = await fetch(`https://gaoney.online/allpost/userpost/${id}?page=1&limit=3`,{
                    headers : {
                        Authorization : "Bearer " + userToken
                    }
                })
                const data = await fetchData.json()
                const fliterData = data?.result?.filter(ost=>ost.userId === id)
                fliterData?.sort((a, b)=> b.createdAt.localeCompare(a.createdAt))
                setState(fliterData)
                setDataLength(data.postCount)
                if(data.next){
                    setHasmore(true)
                    setAddPage(data.next.page)
                }else{
                    setHasmore(false)
                }
            }
            gethData()
        } catch (error) {
            console.log(error)
            
        }
    },[navigate, useId, id, userToken])
    const fetchMoreData =async()=>{
        console.log(addpage)
        setIsLoading(true)
        const fetchData = await fetch(`https://gaoney.online/allpost?page=${addpage}&limit=5`)
        const data = await fetchData.json()
        setIsLoading(false)
        if(data.next){
            setAddPage(data.next.page)
            setHasmore(true)
            setDataLength(data.postCount)
        }else{
            setHasmore(false)
        }
        const sortdata = data.result.sort(()=>Math.random()-0.5)
        setState([...state, ...sortdata])
    }
    return(
        <div className="w-full flex flex-col items-center">
            {isloading && <CardSkeleton card={3}/>}
            <InfiniteScroll 
            dataLength={dataLength} 
            next={fetchMoreData} 
            hasMore={hasMore}
            loader={<CardSkeleton card={3}/>} >
            {
                state?.map(tate=>(
                    <ProfilePostCard key={tate._id} data={tate}/>

                ))
            }
            </InfiniteScroll>
            
        </div>
    )
}

export default ProfilePost