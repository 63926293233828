import { useEffect, useRef, useState} from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useSelector } from "react-redux"



const Anomalous =()=>{
    const inputRef = useRef()
    const [state, setState]=useState(null)
    const userId = useSelector(state=>state.userStore.userId)
    const userToken = useSelector(state=>state.userStore.token)
    const Link = `https://gaoney.com/anonlymous/${userId}`

    useEffect(()=>{
        try {
            const getAnonlymous = async()=>{
                const fetchData = await fetch(`https://gaoney.online/anonlymous/get/${userId}`,{
                    headers : {
                        Authorization : 'Bearer ' + userToken
                    }
                })
                const { data } = await fetchData.json()
                data?.sort((a, b)=> b.createdAt.localeCompare(a.createdAt))
                setState(data)
            }
            getAnonlymous()
        } catch (error) {
            
        }
    },[userId, userToken])

    
    const handleText =(e)=>{
        inputRef.current.select()
        // console.log(e.target)
    }
    const handleDelete =async()=>{
        const formData = new FormData()
        try {
            formData.append("userId", userId)
            const fetchDeleteAnonlymous = await fetch("https://gaoney.online/anonlymous/delete-anonlymous",{
                headers : {
                    Authorization : 'Bearer ' + userToken
                },
                method : "POST",
                body : formData
            })
            await fetchDeleteAnonlymous.json()
            // console.log(data)
            window.location.reload()
            
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="w-full h-dvh flex flex-col items-center pt-20 bg-white" >
            <div className="w-full lg:w-1/3 flex flex-col flex-wrap items-center mb-4">
                <p>Copy Link below to start anonymous</p>
                <div className="w-full flex flex-wrap justify-center my-2">
                    <input ref={inputRef} onFocus={handleText} type="text" className="px-2 my-2 w-96 flex flex-grow-1 py-2 border " readOnly value={Link} />
                    <CopyToClipboard  text={Link}>
                        <button className="mx-2 py-1 px-3 border" >copy</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="w-full lg:w-1/3 flex justify-end px-3">
                <button className="px-3 py-2 rounded-lg border border-blue-500" onClick={handleDelete}>Delete All</button>
            </div>
            <div className="w-full  flex flex-col items-center  px-2" >
                {
                    !state ?(
                        <div  className="h-72 w-full lg:w-1/3 border flex justify-center items-center text-2xl text-white bg-blue-500 rounded-lg">
                            Messages show here
                        </div>
                    ) : (
                        state?.map(tat=>(
                            <div key={tat._id} className="h-72 w-full lg:w-1/3 border flex justify-center items-center text-2xl text-white bg-blue-500 rounded-lg text-center px-3 mt-2">
                                {tat.message}
                            </div>
    
                        ))
                    )
                }
                
            </div>
           
        </div>
    )
}

export  default  Anomalous